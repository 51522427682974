import IService from './iservice';
import config from "@/config";
import store from "@/store/store";
import utils from '@/helpers/utils';

const server_base = config[config.stage].ws_server;

class CustomerService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async create(customer) {
    try {
      let response = await this.http.post(`${ server_base }/api/customer`, { customer });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip, filters) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( filters.company ) query.company = filters.company;
      if ( filters.inStore ) query.inStore = filters.inStore;
      if ( filters.filter ) query.filter = filters.filter;
      if ( filters.tab ) query.tab = filters.tab;
      if ( filters.agentId ) query.agentId = filters.agentId;
      if ( filters.search ) {
        query.search = utils.isUsPhone(filters.search)
            ? utils.NormalizeNumber(filters.search)
            : filters.search;
      }

      this.addLoading('list');
      const response = await this.http.get(`${ server_base }/api/customer`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async listByAgent(limit, skip, filters) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( filters.company ) query.company = filters.company;
      if ( filters.inStore ) query.inStore = filters.inStore;
      if ( filters.filter ) query.filter = filters.filter;
      if ( filters.agentId ) query.agentId = filters.agentId;
      if ( filters.search ) {
        query.search = utils.isUsPhone(filters.search)
            ? utils.NormalizeNumber(filters.search)
            : filters.search;
      }

      this.addLoading('list');
      const response = await this.http.get(`${ server_base }/api/customer/by_agent`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async listByAgentDailyLog(limit, skip, filters = {}) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( filters.company ) query.company = filters.company;
      if ( filters.inStore ) query.inStore = filters.inStore;
      if ( filters.filter ) query.filter = filters.filter;
      if ( filters.agentId ) query.agentId = filters.agentId;
      if ( filters.search ) {
        query.search = utils.isUsPhone(filters.search)
            ? utils.NormalizeNumber(filters.search)
            : filters.search;
      }

      this.addLoading('list');
      const response = await this.http.get(`${ server_base }/api/customer/daily-log-by-agent`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async listInactive(limit, skip, search, filter, company) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( filter ) query.filter = filter;
      if ( company ) query.company = company;
      if ( search ) {
        query.search = utils.isUsPhone(search) ? utils.NormalizeNumber(search) : search;
      }

      this.addLoading('list');
      const response = await this.http.get(`${ server_base }/api/customer/inactive`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async listMediators(limit, skip, filters = {}) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( filters?.search ) {
        query.search = utils.isUsPhone(filters.search)
            ? utils.NormalizeNumber(filters.search)
            : filters.search;
      }

      this.addLoading('list');
      const response = await this.http.get(`${ server_base }/api/customer/mediators`, query);
      this.delLoading('list');
      return response.data;

    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async setAsAvailableToAssign(id) {
    try {
      const response = await this.http.put(`${ server_base }/api/customer/${ id }/set-available-to-assign`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async DailyLoglist(limit, skip, search, company) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( search ) query.search = search;
      if ( company ) query.company = company;

      this.addLoading('list');
      const response = await this.http.get(`${ server_base }/api/customer/daily_log`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async listInPhone(limit, skip, search, filter, company) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( search ) query.search = search;
      if ( filter ) query.filter = filter;
      if ( company ) query.company = company;

      this.addLoading('list-in-phone');
      const response = await this.http.get(`${ server_base }/api/customer`, query);
      this.delLoading('list-in-phone');
      return response.data;
    } catch (e) {
      this.delLoading('list-in-phone');
      return null;
    }
  }

  async globalSearch(limit, skip, search) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( search ) {
        query.search = utils.isUsPhone(search) ? utils.NormalizeNumber(search) : search;
      }

      this.addLoading('list-global');
      const response = await this.http.get(`${ server_base }/api/customer/search/global`, query);
      this.delLoading('list-global');
      return response.data;
    } catch (e) {
      this.delLoading('list-global');
      return null;
    }
  }

  async get(id) {
    try {
      let response = await this.http.get(`${ server_base }/api/customer/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getChatClient(id) {
    try {
      let response = await this.http.get(`${ server_base }/api/customer/chatClient/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async setSmsConsent(id, consent) {
    try {
      let response = await this.http.put(`${ server_base }/api/customer/chatClient/${ id }/consent`, consent);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async toggleSmsBot(id, botActive) {
    try {
      let response = await this.http.put(`${ server_base }/api/customer/${ id }/toggle-bot`, { botActive });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateActive(customer) {
    try {
      let response = await this.http.put(`${ server_base }/api/customer/active`, { customer });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async infoByPhone(phone) {
    try {
      let response = await this.http.get(`${ server_base }/api/customer/info_by_phone/${ phone }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateCustomer(customer) {
    try {
      const response = await this.http.put(`${ server_base }/api/customer`, { customer });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateCustomerInStore(customer) {
    try {
      const response = await this.http.put(`${ server_base }/api/customer/in_store`, { customer });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateFields(id, fields) {
    try {
      let response = await this.http.put(`${ server_base }/api/customer/field`, { id, fields });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateChatClient(customer) {
    try {
      const response = await this.http.put(`${ server_base }/api/customer/chat_client`, customer);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateTags(id, tags) {
    try {
      let response = await this.http.put(`${ server_base }/api/customer/tags`, { id, tags });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async tracking(sid) {
    try {

      let response = await this.http.get(`${ server_base }/api/customer/tracking?sid=${ sid }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async match(customer) {
    try {

      let response = await this.http.post(`${ server_base }/api/customer/match`, customer);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(id) {
    try {
      const response = await this.http.delete(`${ server_base }/api/customer/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async newAssign(id, agent) {
    try {
      const response = await this.http.post(`${ server_base }/api/customer/${ id }/new-assign`, { agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async assign(id, agents, addedAgents, removedAgents) {
    try {
      const response = await this.http.post(`${ server_base }/api/customer/${ id }/assign`,
          { agents, addedAgents, removedAgents }
      );
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async request(id) {
    try {
      const response = await this.http.post(`${ server_base }/api/customer/${ id }/owner-request`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async decline(id, agent_id) {
    try {
      const response = await this.http.post(`${ server_base }/api/customer/${ id }/owner-decline`, { agent_id });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async statistic(id) {
    try {
      this.addLoading('list_customer_statistic');
      let response = await this.http.get(`${ server_base }/api/customer/statistic?filter=${ id }`);
      this.delLoading('list_customer_statistic');
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getSources() {
    try {
      let response = await this.http.get(`${ server_base }/api/customer/sources`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async count(dateRange) {
    try {
      const response = await this.http.get(`${ server_base }/api/customer/statistic/count`, { dateRange });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async countTabs(filters) {
    try {
      const query = { limit: 1, skip: 1 };

      if ( filters.company ) query.company = filters.company;
      if ( filters.inStore ) query.inStore = filters.inStore;
      if ( filters.filter ) query.filter = filters.filter;
      if ( filters.tab ) query.tab = filters.tab;
      if ( filters.agentId ) query.agentId = filters.agentId;
      if ( filters.search ) {
        query.search = utils.isUsPhone(filters.search)
            ? utils.NormalizeNumber(filters.search)
            : filters.search;
      }

      this.addLoading('count-tabs');
      const response = await this.http.get(`${ server_base }/api/customer`, query);
      this.delLoading('count-tabs');
      return response.data;
    } catch (e) {
      this.delLoading('count-tabs');
      return null;
    }
  }

  async areaChart(dateView, agent = null) {
    try {
      const response = await this.http.get(`${ server_base }/api/customer/statistic/area-chart`, { dateView, agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async alreadyCustomer(phone_or_email) {
    try {
      let response = await this.http.get(`${ server_base }/api/customer/exist/${ phone_or_email }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async status72hRule(id) {
    try {
      const response = await this.http.get(`${ server_base }/api/customer/${ id }/72h-rule`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async sendForm(customerId, formType) {
    try {
      const response = await this.http.post(`${ server_base }/api/customer/send-form`,
          { customerId, formType }
      );
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async listOpportunities(limit, skip, search, sort) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( search ) query.search = search;
      if ( sort ) query.sort = sort;

      this.addLoading('list');
      const response = await this.http.get(`${ server_base }/api/customer/opportunities`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async updateOpportunity(customerId, type, reason) {
    try {
      const query = {
        type: type
      }

      if ( reason ) query.reason = reason

      const response = await this.http.put(`${ server_base }/api/customer/${ customerId }/set-opportunity`, query);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async lock(customerId) {
    try {
      const response = await this.http.post(`${ server_base }/api/customer/lock`, { customerId });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async unlock(customerId) {
    try {
      const response = await this.http.post(`${ server_base }/api/customer/unlock`, { customerId });
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default CustomerService;
